@media (max-height: 600px){
  body .Home > article{
    padding-top: 3em;
  }
  body .Background{
    background-position-y: center;
  }
}
@media (max-width: 992px){
  body #Projects{
    overflow: auto scroll;
    overflow-y: hidden;
    flex-direction: column;
    align-content: normal;
    justify-content: center;
    padding: 0;
    width: 59vmax;
    height: 72vh;
    max-width: 100%;
    max-height: 100vh;
    min-height: 90vmin;
    row-gap: 15px;
  }
  body #Projects>.card{
    height: 48%;
    min-width: 100%;
    padding-bottom: .5%;
    min-height: 34vmax;
  }
  #Projects-scroll-indicator{
    display: inline-flex;
    width: 100%;
    flex-flow: row;
    column-gap: 5px;
    justify-content: center;
  }
  #Projects-scroll-indicator > div{
    border-radius: 100%;
    width: 5px;
    height: 5px;
    background-color: white;
    align-self: center;
  }
  body #avatar{
    width: 50%;
    min-width: 35vh;
    max-width: 50vh;
  }
  #skills{
    flex-flow: row wrap;
    column-gap: 5%;
  }
  #skills li{
    margin: 5px;
  }
  body .Home{
    background-size: cover;
    /* contain: style; */
    -webkit-background-size: cover;
    background-attachment: local, fixed;
  }
  body .Background{
    background-size: cover;
    -webkit-background-size: cover;
  }
  #CampusMap .carousel{
    height: 93%;
  }
  .carousel-indicators{
    margin-bottom: 0!important;
  }
  #Projects> .card img{
    max-height: 25vh;
    min-height: 25vmax;
  }
  body form{
    padding-top: 20%;
  }
}

#Scroller {
  justify-content:space-between;
}
#Scroller-active{
  background-color: rgba(0, 0, 0, 0.781);
  justify-content:space-between;
  border-bottom: red solid 5px;
}

.nav-link.active, .navbar-brand{
  text-shadow: 2px 2px 2px black;
}

.About{
  height: 100%;
  min-height: 100vh;
  background-color: #1c222a;
  background-attachment: fixed;
}

#About-card{
  display: flex;
  position: relative;
  top: 10%;
  height: 60%;
  width: 9in;
  max-width: 90%;
  margin: auto;
  background: url(./car.webp);
  background-position: center;
  background-blend-mode: screen;
  background-size: cover;
  background-color: #dddddd;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 10px;
  overflow-y: hidden;
  border: 5px solid gray;
}

#About-card > article {
  margin: auto;
  margin-top: 50px;
  width: 23em;
}
.Home >article{
  padding-top: 7em;
}

#About-card p{
  margin-top: 0;
  color: black;
  padding: 6px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

Figure{
  height: 30%;
  width: 50%;
}
form{
  padding: 5%;
  padding-bottom: 2.5%;
  padding-top: 5%;
  width: 90vh;
  max-width: 100%;
  height: 70%;
  margin: auto;
}

h1{
  padding-top: 5rem;
  font-style: oblique;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-family: 'Righteous';
  width: fit-content;
  color: rgb(0, 225, 255);
  display: block;
  margin: auto;
  text-align: center;
  text-shadow: 2px 3px magenta;

}

#skills{
  padding: 0 3% 0 3%;
  list-style: none;
  display: inline-flex;
  align-content: center;
  width: 100%;
  margin-top: 5%;
  position: fixed;
  top: 60%;
  z-index: -1;
}

ul{
  list-style: none;
  display: flex;
  justify-content: center;
  column-gap: 5%;
  color: snow;
}

#skills li{
  background-color: #ffe007b8;
  /* background: radial-gradient(ellipse at 50%, #D9CA61 5%,#d3b938 40%,#bf5e12 85%); */
  /* filter: blur(0.5px);
  opacity: 70%; */
  font-weight: bold;
  font-family: 'Permanent Marker', cursive;
  text-align: center;
  width: fit-content;
  color: sienna;
  padding: 8px;
  border-radius: 60px;
  overflow:hidden;
 
}

#logo{
  display: block;
  position: absolute;
  height: 15%;
  width: 15%;
  z-index: 1;
}

#avatar{
  display: block;
  width: 25%;
  min-width: 40vh;
  margin: auto;
  border-radius: 100%;
  border: 3px solid #fc333394;
}
.Home{
  min-height: 100vh;
}
.Background{
  position: fixed;
  background-image: linear-gradient(to bottom, #5b505273, transparent, #000000), url(/src/city-dark-lights-2400.webp);
  background-blend-mode: darken;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: 105%;
  -webkit-background-size: 105%;
  -moz-background-size: 105%;
}

#Projects-container{
  min-height: 100vh;
  display: block;
  background-color: #1c222a;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
 
}

#Projects {
  display: flex;
  height: 80%;
  width: 90%;
  flex-flow: row wrap;
  row-gap: 2.5%;
  column-gap: 5%;
  justify-content: center;
  align-content: center;
  max-height: 100vh;
  row-gap: 10px;
  margin: auto;
}
#Projects-container ::-webkit-scrollbar{
  display: none;
}
#Projects .card{
  width: 59vmin;
  aspect-ratio: 1/0.6;
  min-width: 35%;
  max-width: 40%;
  max-height: 50%;
  border-radius: .35rem;
  flex-direction: column;
}

Reactlogo, Htmllogo, Csslogo, Jslogo{
  height: 16px;
  width: 16px;
}
#tech-logo > li{
  width: 20px;
  height: 20px;
  border: none;

}
.card ul{
  color: black;
  padding: 0;
  margin: 0;
  line-height: 0;
  justify-content: center;
}

.card-text li:last-child{
  border: none;
}
div .card-body{
  text-align: center;
  padding:0;
}
#Projects .card-title, .card-text{
  line-height: 1;
  position: relative;
}

.card img{
  margin: auto;
  min-height: 26vmin;
  max-height: 28vh;
  border: 1px solid black;
}
.carousel{
  height: 91%;
}

img{
  display: block;
  width: 100%;
  height: 80%;
  border-radius: .25rem;
}
#jeoparody{
  height: 60%;
  width: auto;
}
.Contact-container{
  height: 100%;
  min-height: 100vh;
  background-color: #1c222a;
  color: aliceblue;
}
footer{
  position: sticky;
  top: 100%;
  row-gap: 10px;
  text-align:center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.popover{
  text-align: center!important;
}

.App-header {
  background-color: #424449;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
